import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ImageTwo from '../components/image'
import Layout from "../components/layout2"
import "../components/layout.css"
const SecondPage = () => (
  <Layout>

<header> </header>
<nav class="a">
    <ul>

      <li>
        <Link to="/about/">Our Team</Link>
      </li>
      <li>
      <a target="_blank" href="https://dl.acm.org/doi/pdf/10.1145/3351095.3372859">
      Paper
      </a>
      </li>
      <li><Link to="/studyguide">Study Guide</Link>
      </li>
      <li><a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vTOjmDqOZyi9XxC5Hiu9uur82WEUk_IGDcWLyBgj4IibAf-WA5QPaLD1qd7kwihCwKvfHaLqiyArTxI/pub">Development</a></li>
      <li><a target="_blank" href="http://rkarkarka.pythonanywhere.com/">Demo</a></li>
      <li>
      <Link to="/casestudy/">Case Study</Link>   </li>
      <li>
      <Link to="/">Study Up</Link>   </li>


    </ul>
  </nav>

<div class="beforecolumnabout">
<br/>
<br />

</div>
<div class="spacing">

</div>
  <div class="columnabout">
  <div class="texts">
  Contact Us: <u><i><a style={{color:"blue"}} href = "mailto:studyupalgo@gmail.com">studyupalgo@gmail.com </a></i></u>
</div>
  <ImageTwo file="about.png" />
  </div>
  <br />
    <div class="columnabout">

  <ImageTwo file="me3.png" />
  </div>

<br />
<br />


<div class="smalllogo">
  <ImageTwo file="mitmedia.png" />
  </div>




  </Layout>
)

export default SecondPage
